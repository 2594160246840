<template>
  <div class="flex">
    <div v-if="showtitle">附件:</div>
    <div class="flex-1" style="text-align: center">
      <a v-for="(li,index) in files?files.split(','):''" :key='index' :href="li" class="as"
         target="_blank">{{ filesub(li) }}</a>
    </div>
  </div>
</template>
<script>
import {filesub} from '../../utils/index'

export default {
  props: {
    files: {
      type: String,
      default: ''
    },
    showtitle: {
      type: Boolean,
      default: false
    },
  },

  data(){
      return {
        filesub:filesub
      }
  }
}
</script>
<style scoped lang="scss">
  .as{
    line-height: 25px;
    color:  #409EFF;
    display: block;
    text-align: left;
  }
</style>
